import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem, MenuList } from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import tilesIcon from '../../images/icons/tiles-icon.svg';
import statusActiveIcon from '../../images/icons/status-active.svg';
import statusNotActiveIcon from '../../images/icons/status-not-active.svg';
import EditIcon from '../icons/editIcon';
import DeleteIcon from '../icons/deleteIcon';
import CommonInfoApp from '../commonInfoApp';
import InfoAuthOTP from '../infoAuthOTP';
import InfoAuthOAUTH2 from '../infoAuthOAUTH2';
import { toggleActive } from '../../api/apps';
import CustomPopover from '../popovers/CustomPopover';
import './style.css';
import { setDeleteAppModal, setEditAppModal } from '../../redux/actions/modals';
import CustomExpandIcon from '../icons/customExpandIcon';
import CodeInfoApp from '../codeInfoApp';
import { codeTypes } from '../../utils/constants';
import SipInfoApp from '../sipInfoApp';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiPaper-elevation1': {
			boxShadow: 'none',
		},
		'& .MuiAccordionSummary-root': {
			border: '1px solid #E0E2E7',
			borderTop: 'none',
			padding: '0 32px 0 16px',
			'&:hover': {
				background: '#F6F6FB',
				'& .custom_expand_icon': {
					background: '#2186F0',
				},
				'& .custom_expand_icon .MuiSvgIcon-root': {
					fill: '#fff',
				},
			},
			// eslint-disable-next-line no-useless-computed-key
			['@media screen and (max-device-width: 1000px)']: {
				borderLeft: 'none',
				borderRight: 'none',
			},
		},
		'& .MuiAccordionSummary-root.Mui-expanded': {
			borderBottom: '1px solid #E0E2E7',
		},
		'& .MuiIconButton-edgeEnd': {
			marginRight: 4,
		},
		'& .MuiIconButton-label': {
			background: '#F6F6FB',
			borderRadius: '50%',
			width: 24,
			height: 24,
		},
		'& .MuiAccordionSummary-root:hover .MuiIconButton-label': {
			background: '#2186F0',
		},
		'& .MuiAccordion-root.Mui-expanded': {
			margin: 0,
			borderBottom: '1px solid #E0E2E7',
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: '12px 0',
		},
		'& .MuiAccordionSummary-content': {
			'& .MuiSvgIcon-root': {
				fill: '#8387A0',
			},
		},
		'& .MuiAccordionDetails-root': {
			background: '#FCFCFE',
			borderLeft: '1px solid #E0E2E7',
			borderRight: '1px solid #E0E2E7',
			borderTop: 'none',
			padding: 0,
			display: 'flex',
			flexDirection: 'column',
			// eslint-disable-next-line no-useless-computed-key
			['@media screen and (max-device-width: 1000px)']: {
				borderLeft: 'none',
				borderRight: 'none',
			},
		},
		'& .MuiStepLabel-label': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			color: '#273270',
		},
		'& .MuiStepLabel-label.Mui-active': {
			fontFamily: 'Inter',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '22px',
			color: '#273270',
		},
		'& .MuiStepLabel-labelContainer': {
			marginLeft: 15,
		},
		'& .MuiStepConnector-root': {
			marginLeft: 5,
			height: 25,
		},
		'& .MuiStepContent-root': {
			marginLeft: 5,
			height: 33,
		},
		'& .MuiCollapse-wrapperInner': {
			textAlign: 'left',

			'& a': {
				marginLeft: 8,
				fontFamily: 'Inter',
				fontStyle: 'normal',
				fontWeight: 'normal',
				fontSize: '15px',
				lineHeight: '22px',
				color: '#2186F0',
				textDecoration: 'none',
				'&:hover': {
					textDecoration: 'underline',
				},
			},
		},
	},
}));

const AccordionApp = (props) => {
	const { app } = props;
	const dispatch = useDispatch();
	const classes = useStyles();
	const [expand, setExpand] = React.useState(false);
	const [openPopper, setOpenPopper] = React.useState(false);

	const anchorRef = React.useRef(null);

	const changeExpand = () => {
		setExpand(!expand);
	};

	const handleToggle = (e) => {
		e.stopPropagation();
		setOpenPopper((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		event.stopPropagation();
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpenPopper(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpenPopper(false);
		} else if (event.key === 'Escape') {
			setOpenPopper(false);
		}
	}

	const editApp = (e) => {
		e.stopPropagation();
		dispatch(
			setEditAppModal({
				show: true,
				appId: app.id,
			})
		);
		setOpenPopper(false);
	};

	const deleteApp = (e) => {
		e.stopPropagation();
		dispatch(
			setDeleteAppModal({
				show: true,
				appId: app.id,
			})
		);
		setOpenPopper(false);
	};

	const ToggleActiveApp = async (e) => {
		e.stopPropagation();
		await toggleActive({ app });
	};

	return (
		<div className={classes.root}>
			<Accordion expanded={expand} onChange={changeExpand}>
				<AccordionSummary aria-controls="panel1a-content" id="accordion-app">
					<div className="accordion_app__header">
						<div className="accordion_app__header__app_name">
							<div className="app_icon">
								<img src={tilesIcon} alt="tiles-icon" />
							</div>
							<span>{app.name}</span>
						</div>
						<div
							className={
								app.active
									? 'accordion_app__header__status'
									: 'accordion_app__header__status hidden_status'
							}
						>
							<div className="accordion_app__header__status_icon">
								<img src={statusActiveIcon} alt="status-active-icon" />
							</div>
							<div className="accordion_app__header__status_text">
								<span className="status_text">Активно</span>
								<span className="status_action" onClick={ToggleActiveApp}>
									остановить
								</span>
							</div>
						</div>
						<div
							className={
								app.active
									? 'accordion_app__header__status hidden_status'
									: 'accordion_app__header__status'
							}
						>
							<div className="accordion_app__header__status_icon">
								<img src={statusNotActiveIcon} alt="status-not-active-icon" />
							</div>
							<div className="accordion_app__header__status_text">
								<span className="status_text">Неактивно</span>
								<span className="status_action" onClick={ToggleActiveApp}>
									запустить
								</span>
							</div>
						</div>
						<div className="accordion_app__header__buttons">
							<CustomExpandIcon expanded={expand} />
							<EditIcon onClick={editApp} />
							<DeleteIcon onClick={deleteApp} />
						</div>
					</div>
					<div className="accordion_app__settings">
						<MoreHorizIcon ref={anchorRef} onClick={handleToggle} />
						<CustomPopover
							open={openPopper}
							handleClose={handleClose}
							anchorEl={anchorRef.current}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							ChildComponent={() => (
								<MenuList
									autoFocusItem={openPopper}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
								>
									<MenuItem onClick={editApp}>Редактировать</MenuItem>
									<MenuItem onClick={deleteApp}>Удалить</MenuItem>
								</MenuList>
							)}
						/>
					</div>
				</AccordionSummary>
				<AccordionDetails>
					<div className={'accordion_app__content__item border_bottom'}>
						<CodeInfoApp app={app}/>
					</div>
					{
						app.code_type === codeTypes.voice ?
							<div className={'accordion_app__content__item border_bottom'}>
								<SipInfoApp app={app}/>
							</div> : null
					}
					<div
						className={
							app.auth_type === 0
								? 'accordion_app__content__item border_bottom'
								: 'hidden_block'
						}
					>
						<CommonInfoApp
							client_id={app.client_id}
							borderBottom={false}
							show={app.auth_type === 0}
						/>
					</div>
					<div
						className={
							app.auth_type === 2
								? 'hidden_block'
								: app.auth_type === 1
								? 'accordion_app__content__item'
								: 'accordion_app__content__item border_bottom'
						}
					>
						<InfoAuthOTP app={app} />
					</div>
					<div
						className={
							app.auth_type === 1
								? 'hidden_block'
								: 'accordion_app__content__item'
						}
					>
						<InfoAuthOAUTH2 app={app} />
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default AccordionApp;
