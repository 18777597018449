import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import NavMenuButton from '../buttons/navMenuButton';
import NavProfile from '../navProfile';
import { setDataPage } from '../../redux/actions/page';
import { getProfile } from '../../api/users';
import CustomSidebarMenu from '../customSidebarMenu';
import SidebarMenuButton from '../buttons/sidebarMenuButton';
import LinksServices from '../buttons/linksServices';
import { URL_SERVICES, URL_SERVICE_CRM_TOOLS, URL_SERVICE_TRUSTED } from '../../utils/constants';
import Apps from '../icons/apps';
import Log from '../icons/log';
import ShopIcon from '../icons/shop';
import Wallet from '../icons/wallet';

const Header = (props) => {
	const dispatch = useDispatch();
	const page = useSelector((state) => state.page);
	const { showApp } = props;
	const [openSidebarMenu, setOpenSidebarMenu] = React.useState(false);

	const changePage = (text) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			onCloseHandle();
		}
	};

	const onOpenHandle = () => {
		setOpenSidebarMenu(true);
	};

	const onCloseHandle = () => {
		setOpenSidebarMenu(false);
	};

	const onClickMenuService = (serviceName) => {
		onCloseHandle();
		switch (serviceName) {
			case 'couner': {
				window.open(`${URL_SERVICES}/${serviceName}`);
				break;
			}
			case 'obzvon': {
				window.open(`${URL_SERVICES}/${serviceName}`);
				break;
			}
			case 'crmtools': {
				window.open(URL_SERVICE_CRM_TOOLS);
				break;
			}
			case 'trusted': {
				window.open(URL_SERVICE_TRUSTED);
				break;
			}
			default: break;
		}
	};

	const MenuList = () => {
		return (
			<div className="header__sidebar_menu__list">
				<div className="header__sidebar_menu__list_services">
					<SidebarMenuButton
						text="Прием показаний"
						page={page.data}
						onClick={() => onClickMenuService('counter')}
					/>
					<SidebarMenuButton
						text="Обзвон должников"
						page={page.data}
						onClick={() => onClickMenuService('obzvon')}
					/>
					<SidebarMenuButton
						text="Обработка заказов"
						page={page.data}
						onClick={() => onClickMenuService('crmtools')}
					/>
					<SidebarMenuButton
						text="Сервис аутентификации"
						page={page.data}
						onClick={() => onClickMenuService('trusted')}
					/>
				</div>
			</div>
		);
	};

	React.useEffect(() => {
		getProfile();
		showApp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<header className="header">
			<div className="header__centralized_container">
				<div className="header__left_side">
					<CustomSidebarMenu
						open={openSidebarMenu}
						onOpenHandle={onOpenHandle}
						onCloseHandle={onCloseHandle}
						ChildComponent={MenuList}
					/>
					<div className="header__services">
						<LinksServices />
					</div>
					<div className="header__logo">
						<div onClick={onOpenHandle}>
							<ShopIcon />
						</div>
						<span>авторизация по телефону</span>
						<p style={{whiteSpace:'nowrap'}}>|  Kloud.one</p>
					</div>
				</div>
				<div className="header__right_side">
					<NavProfile />
				</div>
			</div>
			<div className="navbar">
				<div className="navbar-header">
					<div className="navbar-header__container">
						<NavMenuButton
							text="Приложения"
							page={page.data}
							onClick={changePage}
							icon={<Apps />}
						/>
						<NavMenuButton
							text="Журнал"
							page={page.data}
							onClick={changePage}
							icon={<Log />}
						/>
						<NavMenuButton
							text="Лицензии"
							page={page.data}
							onClick={changePage}
							icon={<Wallet />}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
